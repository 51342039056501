@use "@edgetier/styles/sass/colours";

.attach {
    &,
    label {
        cursor: pointer;
        display: block;

        &:hover {
            color: colours.$flat-peter-river;
        }
    }

    input[type="file"] {
        position: absolute;
        left: -9999px;
    }
}
